<script lang="ts" setup>
import { useDocumentViewer } from './composables'

const drawerRef = ref<HTMLElement>()
const { height } = useElementSize(drawerRef)
const { currentDocumentId, documentURI, onInit } = useDocumentViewer()
</script>

<template>
  <div
    ref="drawerRef"
    class="absolute inset-0 z-[102] w-screen overscroll-y-contain transition-all duration-300 ease-in-out lg:bottom-0 lg:right-0 lg:block lg:w-auto lg:overflow-hidden print:hidden"
  >
    <div class="h-full pt-0">
      <WebViewer
        :key="currentDocumentId"
        :initial-doc="documentURI"
        class="w-full bg-[#212121] px-px"
        :style="{ height: height + 'px' }"
        @init="onInit"
      />
    </div>
  </div>
</template>
