import { getCurrentDivisionId } from '@shared/composables/useDivisions'

export const getDocumentURI = (documentId: Ref<string | undefined>) => {
  const apiURL = useRuntimeConfig().public.apiURL
  const divisionId = getCurrentDivisionId()

  return computed(() => {
    if (!divisionId || !documentId.value) return undefined
    return `${apiURL}/companies/${divisionId}/documents/${documentId.value}/download`
  })
}
