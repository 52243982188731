import type { WebViewerInstance } from '@pdftron/webviewer'
import type { Quad, QuadTuple } from '~/types/pdftron'
import { getDocumentURI } from './index'
import { useLeaseReviewWebViewer } from '@register/components/Review/composables'
import { useAnnotations } from '@shared/composables/useAnnotations'

export const useDocumentViewer = () => {
  const { webViewerInstance, pdfReady, selectedCopiedText, currentDocumentId } =
    useLeaseReviewWebViewer()
  const { state: fieldSelectionState, add: addAnnotations } = useAnnotations()

  const documentURI = getDocumentURI(currentDocumentId)
  const { documents } = useCurrentLease()

  const filename =
    documents.value.find((doc) => documentURI.value?.includes(doc.id))
      ?.filename ?? 'file.pdf'

  // On initialize PDF Tron
  const onInit = (instance: WebViewerInstance) => {
    // Set the WebViewer instance in the store
    webViewerInstance.value = instance

    // Set full width mode
    fullWidthMode(instance)

    // Register listeners
    registerListeners(instance)

    // Add change document button
    addDownloadDocumentButton(instance)
  }

  onUnmounted(() => {
    pdfReady.value = false
  })

  return {
    currentDocumentId,
    documentURI,
    onInit,
  }

  function registerListeners(instance: WebViewerInstance) {
    const { documentViewer } = instance.Core

    documentViewer.addEventListener('documentLoaded', async () => {
      // Set page to 1
      documentViewer.setCurrentPage(1, true)
      pdfReady.value = true
      if (
        fieldSelectionState.id &&
        fieldSelectionState.documentId &&
        fieldSelectionState.bounds &&
        fieldSelectionState.documentId === currentDocumentId.value
      ) {
        addAnnotations(
          fieldSelectionState.documentId,
          fieldSelectionState.bounds,
        )
      }
    })

    documentViewer.addEventListener('documentUnloaded', async () => {
      pdfReady.value = false
    })

    documentViewer.addEventListener('textSelected', onSelectText(instance))
  }

  function fullWidthMode(instance: WebViewerInstance) {
    const { annotationManager } = instance.Core
    const { FitMode, setFitMode } = instance.UI

    setFitMode(FitMode.FitWidth)
    annotationManager.disableFreeTextEditing()
  }

  function onSelectText(instance: WebViewerInstance) {
    const { Math, documentViewer } = instance.Core

    return useDebounceFn((quads, selectedText, pageNumber) => {
      if (!quads || !selectedText || !pageNumber) {
        selectedCopiedText.value = undefined
        return
      }

      const calculateBound = (quad: Quad) => {
        const rect = new Math.Quad(
          ...(Object.values(quad) as QuadTuple),
        ).toRect()

        const w = documentViewer.getPageWidth(pageNumber)
        const h = documentViewer.getPageHeight(pageNumber)
        const padding = 2.8 / h

        return {
          top: rect.y1 / h + padding / 2,
          left: rect.x1 / w,
          width: (rect.x2 - rect.x1) / w,
          height: (rect.y2 - rect.y1) / h - padding * 1.5,
          page: pageNumber,
        }
      }

      selectedCopiedText.value = {
        value: selectedText,
        bounds: {
          bounds: quads.map(calculateBound),
          documentId: currentDocumentId.value!,
        },
        isApproved: false,
      }
    }, 500)
  }

  function openChangeDocument() {
    if (!documentURI.value) return
    const link = document.createElement('a')
    link.href = new URL(documentURI.value).toString()
    link.download = filename
    link.click()
    URL.revokeObjectURL(link.href)
  }

  function addDownloadDocumentButton(instance: WebViewerInstance) {
    const { setHeaderItems } = instance.UI

    setHeaderItems((header) => {
      const renderButton = () => {
        // Button
        const button = document.createElement('div')
        button.className = 'Button download-document'

        // Icon
        const svgWrapper = document.createElement('div')
        svgWrapper.className = 'Icon'

        const icon = document.createElement('div')
        icon.className = 'download-icon'

        svgWrapper.appendChild(icon)
        button.appendChild(svgWrapper)
        button.addEventListener('click', openChangeDocument)
        return button
      }

      const downloadIcon = {
        render: renderButton,
        type: 'customElement',
        toolGroup: 'custom-ribbons-container',
        title: `Download ${filename}`,
      }

      header.unshift(downloadIcon)
    })
  }
}
